import Header from "./components/header/Header";
import { MiddlePart } from "./components/middle-part/MiddlePart";
import Footer from "./components/footer/Footer";
const LandingPage =({})=>{
return (
<>
<Header />
  <MiddlePart />
  <Footer/>
</>
);
};

export default LandingPage;