import Select from 'react-select'


import { useState } from 'react'
import Input from '../input/Input';
import ShadowBox from '../shadow-box/ShadowBox';
import Modal from '@mui/material/Modal';
import { v4 as uuidv4 } from 'uuid'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { storage } from '../../firebase/config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import SearchLocations from '../searchLocations/SearchLocations';

const Events = ({ }) =>
{

  const options = [
    { value: "Music", label: 'Music' },
    { value: "Meetup", label: 'Meetup' },
    { value: "Education", label: 'Education' },
    { value: "Party", label: 'Party' },
    { value: "Exhibition", label: 'Exhibition' },
    { value: "Fashion", label: 'Fashion' },
    { value: "Competition", label: 'Competition' },
    { value: "Sport", label: 'Sport' },
    { value: "Festival", label: 'Festival' },
    { value: "Business", label: 'Business' },
    { value: "Charity", label: 'Charity' },
    { value: "Race", label: 'Race' },
    { value: "Trip", label: 'Trip' },
    { value: "Theater", label: 'Theater' },
    { value: "Fair", label: 'Fair' }
  ];

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [locations, setLocations] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [categories, setCategories] = useState([]);
const [extraInput, setExtraInput] = useState('');
  const [images, setImages] = useState('');
  const [uploadedImages, setUploadedImages] = useState([])

  const [idealStartTime, setIdealStartTime] = useState(null);
  const [idealEndTime, setIdealEndTime] = useState(null);
  const [sending, setSending] = useState(false);
  const [env, setEnv] = useState('dev')
  const [defaultRating, setDefaultRating] = useState(0);
  const [imageUploadError, setImageUploadError] = useState(null)
  const [imageUploaded, setImageUpload] = useState(false)
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [img, setImg] = useState([])
  const [repeatAfter, setRepeatAfter] = useState(0);

  const handleChange = async(e) => {
    setImg([...img, e.target.files[0]])
  }

  const handleUpload = async () => {
    console.log(img[0].name)
    img.forEach(file => {
      let imageRef = ref(storage, `events/${file.name}`)
      try {
      uploadBytes(imageRef, file).then(snapshot => {
        getDownloadURL(imageRef).then(url => {
          console.log(url)
          setUploadedImages(prev => [...prev, url])
          if(url) setImageUpload(true)
          if(!url) setImageUploadError('image not uploaded')
        })
      })
      } catch(e) {
        console.log(e)
        setImageUploadError(e)
      }
    })
  }
  const handleUploadText = async () => {

    // Proceed to upload the imageBlob to Firebase Storage
   images.split(',').forEach(file => {
    fetch(file)
    .then(response => response.blob())
    .then(imageBlob => {
      let imageRef = ref(storage, `events/${uuidv4()}`)
      try {
      uploadBytes(imageRef, imageBlob).then(snapshot => {
        getDownloadURL(imageRef).then(url => {
          console.log(url)
          setUploadedImages(prev => [...prev, url])
          if(url) setImageUpload(true)
          if(!url) setImageUploadError('image not uploaded')
        })
      })
      } catch(e) {
        console.log(e)
        setImageUploadError(e)
      }
    }).catch(error => {
    console.error('Error fetching image:', error);
  });
  })
  
  
  }
  

  return (
    <div className="h-[100vh] overflow-y-scroll" style={{ backgroundImage: 'linear-gradient(136deg, #FFF 7.81%, #C9FFD5 73.27%, #D2F0FD 100%)' }}>
     <Modal
        open={open}
      >
        <div className='bg-stone-100 m-[200px] flex justify-center'
        >
          <div dangerouslySetInnerHTML={{ __html:modalContent}}></div>
          <button type='button' className='bg-amber-300 p-5' onClick={e=>{
            setOpen(false)
          }}>Close</button>
        </div>
      </Modal>
      <div className="pointer-events-none absolute top-0 left-0 bottom-0 right-0 " style={{ backgroundColor: sending ? '#3d3d3d75' : '#ffffff00', }}>{sending ? <div className="bg-white">Recording your data...</div> : <div></div>}</div>
      <form onSubmit={(e) =>
        {
          e.preventDefault()
          if (!sending)
          {
            setSending(true)
            fetch((env==='prod'?'https://app-h4jqfg6cqq-uc.a.run.app':'https://app-4romxvc23a-uc.a.run.app')+'/v1/events/addEvent',
              {
                method: 'POST',
                body: JSON.stringify({
                  defaultRating:defaultRating,
                  location: locations.split(',').map(item => parseFloat(item)),
                  startDate: startDate.toLocaleDateString('en-GB'),
                  endDate: endDate.toLocaleDateString('en-GB'), 
                  category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                  title: title,
                  description: desc,
                  images: images.length > 5 ? [...uploadedImages, ...images.split(',')] : [...uploadedImages],
                  timestamp: new Date(),
                  startTime: startTime !== null ? `${startTime.getHours() < 10 ? `0${startTime.getHours()}` : startTime.getHours()}:${startTime.getMinutes() < 10 ? `0${startTime.getMinutes()}` : startTime.getMinutes()}` : null,
                  endTime: endTime !== null ? `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()}:${endTime.getMinutes() < 10 ? `0${endTime.getMinutes()}` : endTime.getMinutes()}` : null,
                  idealStartTime: idealStartTime !== null ? `${idealStartTime.getHours() < 10 ? `0${idealStartTime.getHours()}` : idealStartTime.getHours()}:${idealStartTime.getMinutes() < 10 ? `0${idealStartTime.getMinutes()}` : idealStartTime.getMinutes()}` : null,
                  idealEndTime: idealEndTime !== null ? `${idealEndTime.getHours() < 10 ? `0${idealEndTime.getHours()}` : idealEndTime.getHours()}:${idealEndTime.getMinutes() < 10 ? `0${idealEndTime.getMinutes()}` : idealEndTime.getMinutes()}` : null,
                  repeatAfter:repeatAfter
                }),
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow'
              })
              .then(response => response.text())
              .then(result =>
              {
                setSending(false)
                alert('Recorded data: ', result)
              })
              .catch(error =>
              {
                alert('Error recording data')
                setSending(false)
              });
              console.log({
                defaultRating:defaultRating,
                location: locations.split(',').map(item => parseFloat(item)),
                  startDate: startDate.toLocaleDateString('en-GB'),
                  endDate: endDate.toLocaleDateString('en-GB'), 
                  category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                  title: title,
                  description: desc,
                  images: images.length > 5 ? [...uploadedImages, ...images.split(',')] : [...uploadedImages],
                  timestamp: new Date(),
                  startTime: startTime !== null ? `${startTime.getHours() < 10 ? `0${startTime.getHours()}` : startTime.getHours()}:${startTime.getMinutes() < 10 ? `0${startTime.getMinutes()}` : startTime.getMinutes()}` : null,
                  endTime: endTime !== null ? `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()}:${endTime.getMinutes() < 10 ? `0${endTime.getMinutes()}` : endTime.getMinutes()}` : null,
                  idealStartTime: idealStartTime !== null ? `${idealStartTime.getHours() < 10 ? `0${idealStartTime.getHours()}` : idealStartTime.getHours()}:${idealStartTime.getMinutes() < 10 ? `0${idealStartTime.getMinutes()}` : idealStartTime.getMinutes()}` : null,
                  idealEndTime: idealEndTime !== null ? `${idealEndTime.getHours() < 10 ? `0${idealEndTime.getHours()}` : idealEndTime.getHours()}:${idealEndTime.getMinutes() < 10 ? `0${idealEndTime.getMinutes()}` : idealEndTime.getMinutes()}` : null,
                  repeatAfter:repeatAfter
              })
          }
        }} className='flex flex-col w-60 mx-auto'>
      <h1 className='text-[16px]'>Select enviroment to post event:</h1>
      <label className='mb-2'>
        Production
        <input 
          checked={env === 'prod'}
          className='ml-5' 
          type='radio' 
          onChange={e => setEnv(e.target.value)} 
          value='prod' 
        />
      </label>
      <label className='mb-2'>
        Development
        <input 
          checked={env === 'dev'}
          className='ml-5' 
          type='radio' 
          onChange={e => setEnv(e.target.value)} 
          value='dev' 
        />
      </label>
      Title*:
      <br />
      <Input setText={setTitle}></Input>
      <br />
      Default rating
      <br />
      <input type='number' onChange={(e)=>{setDefaultRating(parseInt(e.target.value))}}></input>
      <br />
      <br />
      Repeat after ? days
      <br />
      <input type='number'  onChange={(e)=>{setRepeatAfter(parseInt(e.target.value))}}></input>
      Description*:
      <br />
      {/* <Input setText={setDesc}></Input> */}
      <textarea
          cols={50}
          rows={4}
          placeholder="description..." 
          onChange={e => setDesc(e.target.value)}
          class="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
        />
      <br />
      Location* (example: Titova 3 Sarajevo)
      <br />
      <SearchLocations locations={locations} setLocations={setLocations} />
      <br />
        {
          locations&&locations!=='0,0'&&
        <iframe
          width="450"
          height="250"
          frameborder="0" 
          referrerpolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBQ0WyEkHrApFuWBfxCToCSJbA7l81FD9U&q=${locations}&maptype=satellite`}
          allowfullscreen>
        </iframe>}
      <br />
      Start date (press date box to open modal): 
      <DatePicker
        showIcon
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd/MM/yyyy"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        required
      />
      End date (press date box to open modal):
      <DatePicker
        showIcon
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd/MM/yyyy"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        required
      />
      <br />
      <p>These are optional, leave them empty if event don't have time's</p>
      Start time* (example: 14:35 - choose or enter)
      <DatePicker
        showIcon
        selected={startTime}
        onChange={(date) => setStartTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
      />
      <br />
      End time (example: 14:35 - choose or enter)
      <DatePicker
        showIcon
        selected={endTime}
        onChange={(date) => setEndTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
      />
      <br />
      Ideal start time (example: 14:35 - choose or enter)
      <br />
      <DatePicker
        showIcon
        selected={idealStartTime}
        onChange={(date) => setIdealStartTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
      />
      <br />
      Ideal end time (example: 14:35 - choose or enter)
      <br />
      <DatePicker
        showIcon
        selected={idealEndTime}
        onChange={(date) => setIdealEndTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
      />
      <br />
      Images: (example: https://linkToImage.png,https://anotherLinkToImage.jpg)
      <br />
      <input 
        className="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
        type='text'
        onChange={(e) => setImages(e.target.value)}
        value={images}
      />
        <button 
          onClick={handleUploadText}
          className='bg-green-800 text-white p-2 rounded mt-2'
          type='button'
        >
          Upload images
        </button>
      <input type='file' accept="image/*" onChange={handleChange} />
      <div>
        Images choosen:
        <br />
        {img.map((file, i) => (
          <li className='bg-white' key={file.name+i}>
            {file.name}
          </li>
        ))}
        <button 
          onClick={handleUpload}
          className='bg-green-800 text-white p-2 rounded mt-2'
          type='button'
        >
          Upload images
        </button>
        <br />
        {imageUploaded ? <p className='text-green-400 bg-black'>'Uploaded'</p> : 'still not uploaded'}
        {imageUploadError && <p>{imageUploadError}  </p>}
      </div>
    
      <br />
      Select categories
        <Select 
          defaultValue={categories}
          onChange={setCategories}
          options={options}
          isMulti={true}
          menuPlacement='auto'
          required
        />
      <br />
      <div className="m-[auto] flex flex-1 my-24 justify-center ">  
        <ShadowBox w={200}>
          <button type='submit'>Send</button>
        </ShadowBox>
        <ShadowBox w={200}>
          <button type='button' onClick={()=>{
              setModalContent( JSON.stringify({
                defaultRating:defaultRating,
                location: locations.split(',').map(item => parseFloat(item)),
                startDate: startDate.toLocaleDateString('en-GB'),
                endDate: endDate.toLocaleDateString('en-GB'), 
                category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                title: title,
                description: desc,
                images:  [...uploadedImages],
                timestamp: new Date(),
                startTime: startTime !== null ? `${startTime.getHours() < 10 ? `0${startTime.getHours()}` : startTime.getHours()}:${startTime.getMinutes() < 10 ? `0${startTime.getMinutes()}` : startTime.getMinutes()}` : null,
                endTime: endTime !== null ? `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()}:${endTime.getMinutes() < 10 ? `0${endTime.getMinutes()}` : endTime.getMinutes()}` : null,
                idealStartTime: idealStartTime !== null ? `${idealStartTime.getHours() < 10 ? `0${idealStartTime.getHours()}` : idealStartTime.getHours()}:${idealStartTime.getMinutes() < 10 ? `0${idealStartTime.getMinutes()}` : idealStartTime.getMinutes()}` : null,
                idealEndTime: idealEndTime !== null ? `${idealEndTime.getHours() < 10 ? `0${idealEndTime.getHours()}` : idealEndTime.getHours()}:${idealEndTime.getMinutes() < 10 ? `0${idealEndTime.getMinutes()}` : idealEndTime.getMinutes()}` : null,
                repeatAfter:repeatAfter
              },null,2).replace(/\n/g, '<br />&nbsp;'))
              setOpen(true)
          }}>Preview</button>
        </ShadowBox>
      </div>
      </form>
      OR
      <div>
         <textarea className='w-[90%] h-[100vh]' onChange={e=>{
        setExtraInput(e.target.value)
      }}></textarea>
      <button onClick={e=>{
        if (!sending)
        {
          setSending(true)
          fetch((env==='prod'?'https://app-h4jqfg6cqq-uc.a.run.app':'https://app-4romxvc23a-uc.a.run.app')+'/v1/events/addEvent',
            {
              method: 'POST',
              body: extraInput,
              headers: {
                'Content-Type': 'application/json'
              },
              redirect: 'follow'
            })
            .then(response => response.text())
            .then(result =>
            {
              setSending(false)
              alert('Recorded data: ', result)
            })
            .catch(error =>
            {
              alert('Error recording data')
              setSending(false)
            });
            console.log({
              defaultRating:defaultRating,
              location: locations.split(',').map(item => parseFloat(item)),
                startDate: startDate.toLocaleDateString('en-GB'),
                endDate: endDate.toLocaleDateString('en-GB'), 
                category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                title: title,
                description: desc,
                images: images.length > 5 ? [...uploadedImages, ...images.split(',')] : [...uploadedImages],
                timestamp: new Date(),
                startTime: startTime !== null ? `${startTime.getHours() < 10 ? `0${startTime.getHours()}` : startTime.getHours()}:${startTime.getMinutes() < 10 ? `0${startTime.getMinutes()}` : startTime.getMinutes()}` : null,
                endTime: endTime !== null ? `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()}:${endTime.getMinutes() < 10 ? `0${endTime.getMinutes()}` : endTime.getMinutes()}` : null,
                idealStartTime: idealStartTime !== null ? `${idealStartTime.getHours() < 10 ? `0${idealStartTime.getHours()}` : idealStartTime.getHours()}:${idealStartTime.getMinutes() < 10 ? `0${idealStartTime.getMinutes()}` : idealStartTime.getMinutes()}` : null,
                idealEndTime: idealEndTime !== null ? `${idealEndTime.getHours() < 10 ? `0${idealEndTime.getHours()}` : idealEndTime.getHours()}:${idealEndTime.getMinutes() < 10 ? `0${idealEndTime.getMinutes()}` : idealEndTime.getMinutes()}` : null,
            })
        }
      //   setTitle(ob.title)
      //   setDefaultRating(ob.defaultRating)
      // setImages(ob.images)
      // setEndDate(ob.endDate)
      // setStartDate(ob.startDate)
      // setIdealEndTime(ob.idealEndTime)
      // setIdealStartTime(ob.setIdealStartTime)
      // setEndTime(ob.setEndTime)
      // setStartTime(ob.startTime)
      // setDesc(ob.description)
      // setLocations(ob.location.join(','))
      }} type='button' className='bg-amber-300 p-12'>send</button>
      </div>
     
    </div>
  );
};

export default Events;