const Input =({setText=()=>{}})=>{

return (
    <input 
        required
        onChange={(text)=>{setText(text.target.value)}}  
        type='text' 
        class="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]">
    </input>
);
};

export default Input; 