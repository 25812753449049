import React, { useState } from 'react';
import './HeaderStyle.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
      <div className='header'>
        <div className='overlay'></div>
        <div className='navigation'>
          <div className='logo'>
            <p>MAPTOBE</p>
          </div>
          <div className='navbar'>
            {/* <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className='bar'></div>
              <div className='bar'></div>
              <div className='bar'></div>
            </div> */}
            {/* <ul className={`navList ${menuOpen ? 'open' : ''}`}>
              <li>Product</li>
              <li>Services</li>
              <li>Contact</li>
              <li>Log in</li>
              <li>
                <button className='navButton'>Try it free</button>  
              </li> 
            </ul> */}
          </div>
        </div>
        <div className='headerMainSection'>
          <div className='headerLeftSection'>
            <img src={require('../../assets/images/logo-mixed.png')} alt='Logo' />
            <div className='ellipse'></div>
            <p className='leftSectionTitle'>MAPTOBE</p>
            <div className='leftQuote'>
              <img src={require('../../assets/images/quote.png')} alt='quote' /> 
            </div>
            <p className='leftSectionText'>We have launched</p>
            <div className='rightQuote'>
              <img src={require('../../assets/images/quote.png')} alt='quote' /> 
            </div>
          </div>
          <div className='illustration'>
            <img 
              src={require('../../assets/images/paper-plane.png')} 
              alt='Paper plane' 
              className='paperPlane'
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='first curverd line'
              className='firstLine' 
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='second curverd line'
              className='secondLine' 
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='third curverd line'
              className='thirdLine' 
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='fourth curverd line'
              className='fourthLine' 
            />
          </div>
          <div className='headerRightSection'>
            <h2 className='rightSectionTitle'>Connecting you <br /> to the world</h2>
            <p className='rightSectionText'>Get the max out of Sarajevo, find Events, create a list of locations to visit, learn about local culture, and don’t miss out on best that Sarajevo offers with our curated list of your Must Do’s in Sarajevo.</p>
            <div className="downloadButton" >
              <div className='shadowBox'></div>
              <a href="https://play.google.com/store/search?q=maptobe&c=apps" target="_blank" rel="noopener noreferrer" >
                <button
                  className='boxButton'
                >
                Download Now
                </button>
              </a>
            </div>
            <br/>
            <div className="downloadButton" >
              <div className='shadowBox'></div>
              <a href="https://www.instagram.com/maptobe.app" target="_blank" rel="noopener noreferrer" >
                <button
                  className='boxButton'
                >
                Follow us on Instagram
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Header;