/**
 * 
 * @param  bottom,right shaddow offset
 * @param bW borderWidth
 * @param p padding
 * @param r rotation
 * @param w widht
 * @param h height
 * @param invert, if true backgroudn is black, else white
 * @returns 
 */
const ShadowBox=({right=5,bottom=5,p=5,bW=1,r=0,w='auto',h='auto',invertBG=false,invert=false,children={}})=>{
return (
   <div class="relative" style={{transform:"rotate("+r+"deg)",width:w,height:h, }}>
                 <div class="absolute  -z-3  rounded-lg  bottom-0 right-0" style={{top:bottom,left:right,backgroundColor:invertBG?'#fff':"#000"}}></div>
<div class="relative z-2  rounded-lg " style={{bottom:bottom,marginRight:right,padding:p, borderWidth:bW,backgroundColor:invert?'#000':'#fff',borderColor:!invert?'#000':'#fff'}}>
                    {children}
                     </div>
    </div>
  
)
}
export default ShadowBox