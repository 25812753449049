import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { v4 as uuidv4} from 'uuid'

import { useEffect, useState } from 'react'
import Input from '../input/Input';
import ShadowBox from '../shadow-box/ShadowBox';

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { storage } from '../../firebase/config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import SearchLocations from '../searchLocations/SearchLocations';

const V2Events = ({ }) =>
{

  const options = [
    { value: "Music", label: 'Music' },
    { value: "Meetup", label: 'Meetup' },
    { value: "Education", label: 'Education' },
    { value: "Party", label: 'Party' },
    { value: "Exhibition", label: 'Exhibition' },
    { value: "Fashion", label: 'Fashion' },
    { value: "Competition", label: 'Competition' },
    { value: "Sport", label: 'Sport' },
    { value: "Festival", label: 'Festival' },
    { value: "Business", label: 'Business' },
    { value: "Charity", label: 'Charity' },
    { value: "Race", label: 'Race' },
    { value: "Theater", label: 'Theater' },
    { value: "Fair", label: 'Fair' }
  ];

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [locations, setLocations] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [categories, setCategories] = useState('');
  const [rating, setRating] = useState(1);
  const [addDate, setAddDate] = useState(false);
  const [days, setDays] = useState([]);
  const [repeatAfter, setRepeatAfter] = useState(0);

  const [images, setImages] = useState('');
  const [uploadedImages, setUploadedImages] = useState([])

  const [idealStartTime, setIdealStartTime] = useState(null);
  const [idealEndTime, setIdealEndTime] = useState(null);
  const [sending, setSending] = useState(false);
  const [env, setEnv] = useState('dev')

  const [eventGroups, setEventGroups] = useState([])
  const [addedEvent, setAddedEvent] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [modalMsg, setModalMsg] = useState({msg: 'empty', color: 'green'})

  const [imageUploadError, setImageUploadError] = useState(null)
  const [imageUploaded, setImageUpload] = useState(false)

  const [img, setImg] = useState([])

  const handleChange = async(e) => {
    setImg([...img, e.target.files[0]])
  }

  const handleUpload = async () => {
    console.log(img[0].name)
    img.forEach(file => {
      let imageRef = ref(storage, `events/${file.name}`)
      try {
      uploadBytes(imageRef, file).then(snapshot => {
        getDownloadURL(imageRef).then(url => {
          console.log(url)
          setUploadedImages(prev => [...prev, url])
          if(url) setImageUpload(true)
          if(!url) setImageUploadError('image not uploaded')
        })
      })
      } catch(e) {
        console.log(e)
        setImageUploadError(e)
      }
    })
  }

  const getGroups = async () => {
    const res = await fetch('https://app-4romxvc23a-uc.a.run.app/v2/events/getEventsGroups')
    //const res = await fetch('http://127.0.0.1:5001/maptobe-dev/us-central1/app/v2/events/getEventsGroups')
    const json = await res.json()
    return json.events
  }

  useEffect(() => {
    getGroups().then(res => setEventGroups(res))
  }, [])
  
  return (
    <div className="h-[100vh] overflow-y-scroll relative" style={{ backgroundImage: 'linear-gradient(136deg, #FFF 7.81%, #C9FFD5 73.27%, #D2F0FD 100%)' }}>
      {openModal && <p className={`bg-green-600/90 fixed w-full z-20`}>{modalMsg.msg}</p>}
      <div className="pointer-events-none absolute top-0 left-0 bottom-0 right-0 " style={{ backgroundColor: sending ? '#3d3d3d75' : '#ffffff00', }}>{sending ? <div className="bg-white">Recording your data...</div> : <div></div>}</div>
      <form onSubmit={(e) =>
        {
          e.preventDefault()
          if(days.length > 0) {
            if (!sending)
            {
              setSending(true)
              fetch("https://app-4romxvc23a-uc.a.run.app/v2/events/addEvent",
                {
                  method: 'POST',
                  body: JSON.stringify({
                    repeatAfter: parseInt(repeatAfter),
                    category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                    title: title,
                    defaultRating: parseInt(rating),
                    days: days,
                    timestamp: new Date()
                  }),
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  redirect: 'follow'
                })
                .then(response => response.text())
                .then(result =>
                {
                  setSending(false)
                  alert('Recorded data: ', result)
                })
                .catch(error =>
                {
                  alert('Error recording data')
                  setSending(false)
                });
            }
            console.log({
              repeatAfter: repeatAfter,
              category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
              title: title,
              defaultRating: rating,
              days: days,
              timestamp: new Date()
            })
          } else {
            alert('add at least one date')
          }
        }} className='flex flex-col w-60 mx-auto'>
      <a 
        href='https://maptobe.com/admin/groupEvents' 
        target='_blank' 
        className='underline decoration-sky-500 text-sm'
      >
        Go to add event group
      </a>
      <h1 className='text-[16px]'>Select enviroment to post event:</h1>
      <label className='mb-2'>
        Production
        <input 
          checked={env === 'prod'}
          className='ml-5' 
          type='radio' 
          onChange={e => setEnv(e.target.value)} 
          value='prod' 
        />
      </label>
      <label className='mb-2'>
        Development
        <input 
          checked={env === 'dev'}
          className='ml-5' 
          type='radio' 
          onChange={e => setEnv(e.target.value)} 
          value='dev' 
        />
      </label>
      <br />
      Title*:
      <br />
      <Input setText={setTitle}></Input>
      <br />
      Select categories
        <Select 
          defaultValue={categories}
          onChange={setCategories}
          options={options}
          isMulti={true}
          menuPlacement='auto'
          required
        />
      <br />
      Default rating for even t (1-5)
      <input type='number' required min={1} max={5} value={rating} onChange={(e => setRating(e.target.value))} />

      Repeat event after what days (if event don't repeat set zero(0))
      <input type='number' required min={0} value={repeatAfter} onChange={(e => setRepeatAfter(e.target.value))} />

      {days && (
        <div>
          <p>Added date's:</p>
          {days.map(day => (
            <div className='bg-sky-900 rounded-r-md mt-1'>
              <div className=' flex items-center justify-center gap-5 relative'>
                <img src={day.images.length > 0 ? day.images[0] : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJiT-UHSm6w0Jperb8SitpfoAKeMUE3uynPg5YO-2Drw&s'} className='absolute left-[-15px] rounded-full w-8 h-8 border-2 border-sky-900' />
                <p className=' text-white'>{day?.date} - {day?.startTime}</p>
                <img
                  className='w-5 h-5 cursor-pointer'
                  src={require('../../assets/images/remove.png')}
                  onClick={() => setDays(days.filter(filterDay => filterDay.id !== day.id))}
                />
              </div>

              <p className='text-white text-[11px] w-full'>{day?.description.slice(0,80)}...</p>
            </div>
          ))}
        </div>
      )}

      <button
        className='mt-5 bg-sky-600 text-white p-1 disabled:bg-gray-700/50 rounded drop-shadow-lg'
        onClick={() => setAddDate(true)}
        disabled={addDate}
      >
        New date
      </button>
      
      {addDate && <div className='border border-black rounded bg-gray-200/50 p-3'>
        Description*:
        <br />
        {/* <Input setText={setDesc}></Input> */}
        <textarea
            cols={50}
            rows={4}
            placeholder="description..." 
            onChange={e => setDesc(e.target.value)}
            class="w-52 p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
          />
        <br />
        Location* (example: Titova 3 Sarajevo)
        <br />
        <SearchLocations locations={locations} setLocations={setLocations} />
        <br />
          {
            locations&&locations!=='0,0'&&
          <iframe
            width="450"
            height="250"
            frameborder="0" 
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBQ0WyEkHrApFuWBfxCToCSJbA7l81FD9U&q=${locations}&maptype=satellite`}
            allowfullscreen>
          </iframe>}
        <br />
        Start date (press date box to open modal): 
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          dateFormat="dd/MM/yyyy"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          required
        />        
        <br />
        <p className='text-md font-bold'>If event don't have time's, leave them empty</p>
        Start time* (example: 14:35 - choose or enter)
        <DatePicker
          showIcon
          selected={startTime}
          onChange={(date) => setStartTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
        <br />
        End time (example: 14:35 - choose or enter)
        <DatePicker
          showIcon
          selected={endTime}
          onChange={(date) => setEndTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
        <br />
        Ideal start time (example: 14:35 - choose or enter)
        <br />
        <DatePicker
          showIcon
          selected={idealStartTime}
          onChange={(date) => setIdealStartTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
        <br />
        Ideal end time (example: 14:35 - choose or enter)
        <br />
        <DatePicker
          showIcon
          selected={idealEndTime}
          onChange={(date) => setIdealEndTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
        <br />
        Images: (example: https://linkToImage.png,https://anotherLinkToImage.jpg)
        <br />
        <input 
          className="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
          type='text'
          onChange={(e) => setImages(e.target.value)}
          value={images}
        />
        <input type='file' accept="image/*" onChange={handleChange} />
        <div>
          Images choosen:
          <br />
          {img.map((file, i) => (
            <li className='bg-white' key={file.name+i}>
              {file.name}
            </li>
          ))}
          <button 
            onClick={handleUpload}
            className='bg-green-800 text-white p-2 rounded mt-2'
            type='button'
          >
            Upload images
          </button>
          <br />
          {imageUploaded ? <p className='text-green-400 bg-black'>'Uploaded'</p> : 'still not uploaded'}
          {imageUploadError && <p>{imageUploadError}  </p>}
        </div>

        <button 
          className='mt-3 bg-sky-600 text-white p-2 rounded drop-shadow-2xl'
          onClick={() => {
            setAddDate(false)
            setDays(prev => [...prev, {
                  id: uuidv4(),
                  location: locations.split(',').map(item => parseFloat(item)),
                  date: startDate.toLocaleDateString('en-GB'),
                  description: desc,
                  images: images.length > 5 ? [...uploadedImages, ...images.split(',')] : [...uploadedImages],
                  startTime: startTime !== null ? `${startTime.getHours() < 10 ? `0${startTime.getHours()}` : startTime.getHours()}:${startTime.getMinutes() < 10 ? `0${startTime.getMinutes()}` : startTime.getMinutes()}` : null,
                  endTime: endTime !== null ? `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()}:${endTime.getMinutes() < 10 ? `0${endTime.getMinutes()}` : endTime.getMinutes()}` : null,
                  idealStartTime: idealStartTime !== null ? `${idealStartTime.getHours() < 10 ? `0${idealStartTime.getHours()}` : idealStartTime.getHours()}:${idealStartTime.getMinutes() < 10 ? `0${idealStartTime.getMinutes()}` : idealStartTime.getMinutes()}` : null,
                  idealEndTime: idealEndTime !== null ? `${idealEndTime.getHours() < 10 ? `0${idealEndTime.getHours()}` : idealEndTime.getHours()}:${idealEndTime.getMinutes() < 10 ? `0${idealEndTime.getMinutes()}` : idealEndTime.getMinutes()}` : null,
            }])
          }}
        >
          Add date to event
        </button>
      </div>}
      
      <br />

      <h1>Groups available: (when you click add, it adds that event to group of events)</h1>
      <div className='bg-sky-600 p-1'>
        {eventGroups && (
          eventGroups.map(group => (
            <div className='text-white bg-sky-900 mb-1 last-of-type:mb-0'>
              <div className='rounded flex justify-between p-1' key={group.id}>
                <p
                  onClick={async () => {
                    group.events = []
                    group.events.push({
                      id: uuidv4(),
                      repeatAfter: parseInt(repeatAfter),
                      category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                      title: title,
                      defaultRating: parseInt(rating),
                      days: days,
                      timestamp: new Date()
                    })
                    //const res = await fetch("http://127.0.0.1:5001/maptobe-dev/us-central1/app/v2/events/addEventToGroup", {
                    const res = await fetch("https://app-4romxvc23a-uc.a.run.app/v2/events/addEventToGroup", {
                      method: 'POST',
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({events: group}),
                      redirect: 'follow',
                      mode: 'no-cors'
                    })
                    if(res.status === 0) {
                      setOpenModal(true)
                      setModalMsg({color: 'green', msg: 'Succesfully added!'})
                      setTimeout(() => {
                        setOpenModal(false)
                      }, 1000)
                    } else {
                      setOpenModal(true)
                      setModalMsg({color: 'red', msg: 'Error adding event'})
                      setTimeout(() => {
                        setOpenModal(false)
                      }, 1000)
                    }
                    getGroups().then(res => setEventGroups(res))
                  }} 
                  className='bg-green-500 text-black p-[1px] rounded-full cursor-pointer text-sm'
                >
                  Add to
                </p>
                <p>Title: {group.title}</p>
              </div>

              <div className='bg-white text-black'>
                <p className='font-bold text-md'>Events:</p>
                {group?.events.map(evt => (
                  <div className='flex justify-between px-1'>
                    <p>{evt.title}</p>
                    <img 
                      src={require('../../assets/images/remove.png')}
                      className='w-5 h-5 cursor-pointer'
                      onClick={async () => {
                        //group.events.filter(oldEvt => oldEvt.id !== group.id)
                        group.events = group.events.filter(filteredEvent => filteredEvent.id !== evt.id)
                        console.log({events:group})
                        //const res = await fetch("http://127.0.0.1:5001/maptobe-dev/us-central1/app/v2/events/removeEventFromGroup", {
                        const res = await fetch("https://app-4romxvc23a-uc.a.run.app/v2/events/removeEventFromGroup", {
                          method: 'POST',
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({events: group}),
                          redirect: 'follow',
                          mode: 'no-cors'
                        })
                        if(res.status === 0) {
                          setOpenModal(true)
                          setModalMsg({color: 'green', msg: 'Succesfully removed!'})
                          setTimeout(() => {
                            setOpenModal(false)
                          }, 1000)
                        } else {
                          setOpenModal(true)
                          setModalMsg({color: 'red', msg: 'Error removing event'})
                          setTimeout(() => {
                            setOpenModal(false)
                          }, 1000)
                        }
                        getGroups().then(res => setEventGroups(res))
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
      <div className="m-[auto] flex flex-1 my-24 justify-center ">  
        <ShadowBox w={200}>
          <button type='submit'>Send</button>
        </ShadowBox>
      </div>
      </form>
    </div>
  );
};

export default V2Events;