import { useState } from 'react'
import Input from '../input/Input';
import ShadowBox from '../shadow-box/ShadowBox';

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { storage } from '../../firebase/config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import SearchLocations from '../searchLocations/SearchLocations';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'

const Locations =({})=>{
   
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [locations, setLocations] = useState('0,0');
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [categories, setCategories] = useState('');
    const [personality, setPersonality] = useState('');
    const [images, setImages] = useState('');
    const [uploadedImages, setUploadedImages] = useState([])

    const [idealStartTime, setIdealStartTime] = useState(new Date());
    const [idealEndTime, setIdealEndTime] = useState(new Date());
    const [rating, setRating] = useState(1);
    const [sending, setSending] = useState(false);

    const [env, setEnv] = useState('dev')

    const [imageUploadError, setImageUploadError] = useState(null)
    const [imageUploaded, setImageUpload] = useState(false)

    const [img, setImg] = useState([])

    const [loadingUpload, setLoadingUpload] = useState(false)

    const handleChange = async(e) => {
      setImg([...img, e.target.files[0]])
    }

    const handleUpload = async () => {
      setLoadingUpload(true)
      img.forEach(file => {
        let imageRef = ref(storage, `locations/${file.name}`)
        try {
        uploadBytes(imageRef, file).then(snapshot => {
          getDownloadURL(imageRef).then(url => {
            console.log(url)
            setUploadedImages(prev => [...prev, url])
            if(url) setImageUpload(true)
            if(!url) setImageUploadError('image not uploaded')
            setLoadingUpload(false)
          }).catch(e => {
            console.log(e)
            setImageUploadError(e)
            setLoadingUpload(false)
          })
        })
        } catch(e) {
          console.log(e)
          setImageUploadError(e)
          setLoadingUpload(false)
        }
      })
    }

    const options = [
      { value: "Museum", label: 'Museum' },
      { value: "History", label: 'History' },
      { value: "Coffee", label: 'Coffee' },
      { value: "Nature", label: 'Nature' },
      { value: "Restaurants", label: 'Restaurants' },
      { value: "Activities", label: 'Activities' },
      { value: "Art", label: 'Art' },
      { value: "Entertainment", label: 'Entertainment' },
      { value: "Religion", label: 'Religion' },
      { value: "Shopping", label: 'Shopping' },
      { value: "View", label: 'View' },
      { value: "Workspace", label: 'Workspace' },
      { value: "Attractions", label: 'Attractions' }
    ];

    const personalityOptions = [
      { value: "Party lover", label: 'Party lover' },
      { value: "Introvert", label: 'Introvert' },
      { value: "Extrovert", label: 'Extrovert' },
      { value: "Nature lover", label: 'Nature lover' },
      { value: "History buff", label: 'History buff' },
      { value: "Foodie", label: 'Foodie' },
      { value: "Coffee lover", label: 'Coffee lover' },
      { value: "Remote worker", label: 'Remote worker' },
    ]

    return (
      <div class="h-[100vh] overflow-y-scroll" style={{ backgroundImage: 'linear-gradient(136deg, #FFF 7.81%, #C9FFD5 73.27%, #D2F0FD 100%)' }}>
        <div class="pointer-events-none absolute top-0 left-0 bottom-0 right-0 " style={{ backgroundColor: sending ? '#3d3d3d75' : '#ffffff00', }}>{sending ? <div class="bg-white">Recording your data...</div> : <div></div>}</div>
          <form onSubmit={(e) =>
          {
            e.preventDefault()
            if (!sending)
            {
            setSending(true)
              fetch((env==='prod'?
               'https://app-h4jqfg6cqq-uc.a.run.app' :
                'https://app-4romxvc23a-uc.a.run.app')
                +'/v1/locations/addLocations',
                {
                  method: 'POST',
                  body: JSON.stringify({
                    lat: parseFloat(locations.split(',')[0]),
                    lng: parseFloat(locations.split(',')[1]),
                    categories: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                    title: title,
                    description: desc,
                    imgUris: images.length > 5 ? [...uploadedImages, ...images.split(',')] : [...uploadedImages],
                    defaultRating: parseInt(rating),
                    isRoute: false,
                    liked: false,
                    notification: false,
                    personalityRaitingProperty: personality["value"],
                    timestamp: new Date()
                  }),
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  redirect: 'follow'
                })
                .then(response => response.text())
                .then(result =>
                {
                //  console.log('resutl=j',result,JSON.stringify(result))
                  setSending(false)
                  alert('Recorded data: ', result)
                })
                .catch(error =>
                {
                  alert('Error recording data')
                  setSending(false)
                });
            }
          }} className='flex flex-col w-60 mx-auto'>
            <h1 className='text-[16px]'>Select enviroment to post location:</h1>
            <label className='mb-2'>
              Production
              <input 
                checked={env === 'prod'}
                className='ml-5' 
                type='radio' 
                onChange={e => setEnv(e.target.value)} 
                value='prod' 
              />
            </label>
            <label className='mb-2'>
              Development
              <input 
                checked={env === 'dev'}
                className='ml-5' 
                type='radio' 
                onChange={e => setEnv(e.target.value)} 
                value='dev' 
              />
            </label>
        Title*:
        <br />
        <Input setText={setTitle}></Input>
        <br />
        Description*:
        <br />
        {/* <Input setText={setDesc}></Input> */}
        <textarea
          cols={50}
          rows={4}
          placeholder="description..." 
          onChange={e => setDesc(e.target.value)}
          class="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
        />
        <br />
        Personality rating property:
        <br />
        <Select 
          required
          options={personalityOptions}
          defaultValue={personality}
          onChange={setPersonality}
          menuPlacement='auto'
        />
        <br />
        Location* (example: Titova 3 Sarajevo)
        <br />
        <SearchLocations locations={locations} setLocations={setLocations} />
        <br />
        {
          locations&&locations!=='0,0'&&
        <iframe
          width="450"
          height="250"
          frameborder="0" 
          referrerpolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBQ0WyEkHrApFuWBfxCToCSJbA7l81FD9U&q=${locations}&maptype=satellite`}
          allowfullscreen>
        </iframe>}
        <br />
        default rating* (1-5)
        <br />
        {/* <Input setText={setRating}></Input> */}
        <input type='number' required min={1} max={5} value={rating} onChange={(e => setRating(e.target.value))} />
        <br />
        Images: (example: https://linkToImage.png,https://anotherLinkToImage.jpg)
      <br />
      <input 
        className="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
        type='text'
        onChange={(e) => setImages(e.target.value)}
        value={images}
      />
      <input type='file' accept="image/*" onChange={handleChange} />
      <div>
        Images choosen:
        <br />
        {img.map((file, i) => (
          <li className='bg-white' key={file.name+i}>
            {file.name}
          </li>
        ))}
        <button
          type='button' 
          onClick={handleUpload}
          className='bg-green-800 text-white p-2 rounded mt-2'
        >
          Upload images  {loadingUpload && 'Loading...'}
        </button>
        <br />
        {imageUploaded ? <p className='text-green-400 bg-black'>Uploaded</p> : 'still not uploaded'}
        {imageUploadError && <p>{imageUploadError}  </p>}
      </div>
    
      <br />
      Select available categories
        <Select 
          required
          defaultValue={categories}
          onChange={setCategories}
          options={options}
          isMulti={true}
          menuPlacement='auto'
        />
      <br />
      <div class="m-[auto] flex flex-1 my-24 justify-center ">  <ShadowBox w={200} >
          <button type='submit'>Send</button>
        </ShadowBox>
        </div>
        </form>
      </div>
    );

};

export default Locations;