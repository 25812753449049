import React from 'react';
import './FooterStyle.css';
import { useState } from 'react';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [message, setMessage] = useState('');


  const checkEmailValidity = () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailPattern.test(email));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkEmailValidity();

    if (isValidEmail) {
      sendEmailToBackend(email);
      
    } else {
      setIsValidEmail(false);
    }
  };

  const sendEmailToBackend = async (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(email)){
      setMessage('Please enter a valid email address.');
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    };
    try {
      const response = await fetch('https://app-h4jqfg6cqq-uc.a.run.app/web/newsletter', requestOptions);

      if(response.ok){
        console.log('Email address added successfully!:)');
        setMessage('Email address added successfully!');
      } else {
        console.log('There was an error with adding an email address!:(');
        setMessage('There was an error with adding an email address!');
      }
    } catch(error) {
      console.error('There was an error with the request processing.', error);
      setMessage('There was an error with the request processing.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(true);
  };

 

  return (
    <div className="footer-container">
      <div className="maptobe-container">
        <h1 className="maptobe-title">MAPTOBE</h1>
        <p className="text-container">
          Place to find the best locations and keep a track of all the hottest events in one place.
        </p>
        <p className="copyright">© Copyright </p>
      </div>
      <div className='spacer'></div>
      <div className="contactus-container">
        <div className="contact-us">CONTACT US</div>
        <div className="text-wrapper">maptobe.app@gmail.com</div>
        {/* <div className="text-wrapper">1-800-200-300</div>
        <p className="text-wrapper">321 High Rd, Romford RM6 6AX, United Kingdom</p> */}
      </div>
      {/* <div className="uptodate-container">
        <div className="stay-up-to-date">STAY UP TO DATE</div>
        <div className="subscribe">Subscribe to our newsletter.</div>
        <div className={`basic-input${!isValidEmail ? ' error' : ''}`}>
          <input
            className="input-text"
            placeholder="yourname@email.com"
            type="email"
            value={email}
            onChange={handleEmailChange}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }}
          />
          <div
            className={`icon-send-bold${!isValidEmail ? ' disabled' : ''}`}
            onClick={handleSubmit}
          ></div>
        </div>
        {!isValidEmail && (
          <p className="error-message">{message}</p>
        )}
      </div> */}
    </div>
  );
};

export default Footer;
